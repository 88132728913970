// Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted.

import {
  formatDistanceToNow,
  lightFormat,
  isFuture as _isFuture,
  isPast as _isPast,
  differenceInSeconds as _differenceInSeconds,
} from 'date-fns'

export function ago(d) {
  return formatDistanceToNow(
    new Date(d),
    {addSuffix: true}
  )
}

export function prettyDatetime (d) {
  return lightFormat(new Date(d), 'yyyy-MM-dd HH:mm')
}

export function prettyDate (d) {
  return lightFormat(new Date(d), 'yyyy-MM-dd')
}

export function isFuture (d) {
  return _isFuture(new Date(d))
}

export function isPast (d) {
  return _isPast(new Date(d))
}

export function differenceInSeconds (d1, d2) {
  return _differenceInSeconds(new Date(d1), new Date(d2))
}
